import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hey, space fans! Today we're going to be jumping into warp speed on our canvas. A finished-ish version will look like the below:`}</p>
    <h2>{`Setup`}</h2>
    <p>{`First, let's set up a canvas. I'll be working in Codepen, and I'd like to start with a black, centered canvas:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/XWmpbpo"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/XWmpbpo/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h2>{`Canvas origin (center)`}</h2>
    <p>{`Next up, I want to change the origin (the center) of the canvas. By default, when we ask for `}<inlineCode parentName="p">{`(0,0)`}</inlineCode>{` of the canvas, it's at the very top-left corner of the canvas. For our hyperspace, though, it's going to be easier if move the center of the canvas to, well, the center of the canvas. To do that, we need to use `}<em parentName="p">{`translate`}</em>{`, which changes where the canvas believes `}<inlineCode parentName="p">{`(0,0)`}</inlineCode>{` to be. The center of the canvas is going to be at half the height and half the width, so we'll need to add:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// move the origin to the center
context.translate(size / 2, size / 2)
`}</code></pre>
    <p>{`I also just added a little circle at `}<inlineCode parentName="p">{`(0,0)`}</inlineCode>{` to ensure that the center is where we think it is.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/OJyWVEx"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/OJyWVEx/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h2>{`Drawing a circle with lines`}</h2>
    <p>{`As we're traveling through hyperspace, we're moving so fast that the stars we see look like lines. And the lines always look as though they're radiating out from the center, the dot in the distance that we're traveling towards. So our first step is going to be drawing a bunch of lines radiating out from the origin of the canvas.`}</p>
    <h3>{`Setting the angle`}</h3>
    <p>{`A circle is 360 degrees. And in the canvas 0 degrees maps to a line moving directly to the right of the origin. 90 degrees is straight down, 180 is straight left, 270 is up, and back to 360 is right again.`}</p>
    <p>{`So, if we wanted to make a line radiating out at every angle, the pseudocode would look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`for (let angle = 0; angle < 360; angle++) {
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(/* a point that we draw at angle */)
}
`}</code></pre>
    <p>{`How do we figure out the `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` values to make that line, though?`}</p>
    <p>{`Well, this is where the Unit Circle from any trigonometry course you've taken will help. If you've never taken trigonometry or don't remember, fret not! We only need to know a few things from it:`}</p>
    <ol>
      <li parentName="ol">{`we can take the `}<inlineCode parentName="li">{`cosine`}</inlineCode>{` of any angle to figure out the `}<inlineCode parentName="li">{`x`}</inlineCode>{` value of a line with length 1 that extends out from the origin at that angle`}</li>
      <li parentName="ol">{`we can take the `}<inlineCode parentName="li">{`sine`}</inlineCode>{` of any angle to figure out the `}<inlineCode parentName="li">{`y`}</inlineCode>{` value of a line with length 1 that extends out from the origin at that angle`}</li>
      <li parentName="ol">{`instead of using degrees, we'll want to use radians, which can be calculated as `}<inlineCode parentName="li">{`(PI / 180) * angle`}</inlineCode></li>
    </ol>
    <h3>{`Finding the x and y values`}</h3>
    <p>{`With these three bits of information, let's update our `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop to draw some lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`for (let angle = 0; angle < 360; angle++) {
  const radians = (Math.PI / 180) * angle
  const x = Math.cos(radians)
  const y = Math.sin(radians)
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(x, y)
  context.strokeStyle = "tomato"
  context.stroke()
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/VwvPveb"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/VwvPveb/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Making the lines longer`}</h3>
    <p>{`It's working! Well, I `}<em parentName="p">{`think`}</em>{` it is, anyway. It mostly just looks like a tiny tomato-colored dot in the center of the screen. So, let's make the lines a little longer. From above, remember that `}<inlineCode parentName="p">{`cosine`}</inlineCode>{` and `}<inlineCode parentName="p">{`sine`}</inlineCode>{` are finding the `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` values of a line that's of length `}<inlineCode parentName="p">{`1`}</inlineCode>{` from the origin.`}</p>
    <p>{`If we want to make the lines longer than `}<inlineCode parentName="p">{`1`}</inlineCode>{`, we just need to multiply the `}<inlineCode parentName="p">{`cosine`}</inlineCode>{` and `}<inlineCode parentName="p">{`sine`}</inlineCode>{` by the length we want. Let's use the `}<inlineCode parentName="p">{`size / 2`}</inlineCode>{` as the multiplier for now. We'd expect the lines to touch each axis, since that's how var we've translated the origin:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`for (let angle = 0; angle < 360; angle++) {
  const radians = (Math.PI / 180) * angle
  const x = Math.cos(radians) * (size / 2)
  const y = Math.sin(radians) * (size / 2)
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(x, y)
  context.strokeStyle = "tomato"
  context.stroke()
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/vYNgNXe?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/vYNgNXe?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Finding the max length`}</h3>
    <p>{`Woo! We've got lines radiating out from the center! We've still got some uncovered space, though, so let's make the lines a little bit longer. The longest that the line would be is the distance from the origin to any of the corners. To find that, we'll just use the pythagorean theorem - `}<inlineCode parentName="p">{`a*a + b*b = c*c`}</inlineCode>{`, with `}<inlineCode parentName="p">{`a`}</inlineCode>{` as the x distance, `}<inlineCode parentName="p">{`b`}</inlineCode>{` as the y distance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const xDistance = canvas.width / 2 // how far we translated the center on the x-axis
const yDistance = canvas.height / 2 // how far we translated the center on the y-axis
const maxLength = Math.sqrt(xDistance * xDistance + yDistance * yDistance)

for (let angle = 0; angle < 360; angle++) {
  /* ... */
  const x = Math.cos(radians) * maxLength
  const y = Math.sin(radians) * maxLength

  /* ... */
}
`}</code></pre>
    <p>{`With this, the lines should extend all the way to the corner of the canvas. The line will extend off of the canvas in most of the cases, but that's okay! The canvas will just ignore those pixels and not draw them.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/qBORbOo?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/qBORbOo?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h2>{`Introducing some randomness`}</h2>
    <p>{`This is all well and good, but it still doesn't look like hyperspace. In hyperspace, there are lines of different widths, different lengths, different starting and ending positions, and different colors. And they also aren't equally distributed around the center. Let's add some randomness!`}</p>
    <h3>{`Random number and angle of lines`}</h3>
    <p>{`First, let's pick a number of lines and assign random angles to each of them. It will help to create a section of our canvas where we define all of these knobs and randomization variables we'll introduce. And then instead of using an angle from 0 - 360, we'll just create a line from 0 to the maximum number of lines. And inside the loop, we can just create a random angle that we'll use for the line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const MAX_NUMBER_OF_LINES = 200

// KNOBS TO ADJUST
const MAXIMUM_NUMBER_OF_LINES = 200

// draw a line from a random angle in a circle
for (let nthLine = 0; nthLine < MAXIMUM_NUMBER_OF_LINES; nthLine++) {
  const angle = Math.random() * 360
  /*
    the rest of the for loop is unchanged
    ...
  */
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/yLYgeMg"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/yLYgeMg/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Random width of lines`}</h3>
    <p>{`Bigger stars in hyperspace = wider lines when we're moving past them, so let's add some variance in the stroke width on the canvas. We'll add knobs for a minimum and maximum line width, and then we'll create a function that gives us a random integer between those two values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// KNOBS TO ADJUST
const MAXIMUM_NUMBER_OF_LINES = 200
const MINIMUM_LINE_WIDTH = 1
const MAXIMUM_LINE_WIDTH = 3

// grab a random width between the min and max
const getRandomLineWidth = () => {
  // math.random() excludes the upper limit, so we'll add one here to ensure we can actually return the MAXIMUM_LINE_WIDTH
  const max = MAXIMUM_LINE_WIDTH + 1
  const min = MINIMUM_LINE_WIDTH
  return Math.floor(Math.random() * (max - min)) + min
}

// draw a line from a random angle in a circle
for (let nthLine = 0; nthLine < MAXIMUM_NUMBER_OF_LINES; nthLine++) {
  const angle = Math.random() * 360
  const radians = (Math.PI / 180) * angle
  const x = Math.cos(radians) * maxLength
  const y = Math.sin(radians) * maxLength

  // use a random line width
  const lineWidth = getRandomLineWidth()
  context.lineWidth = lineWidth

  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(x, y)
  context.strokeStyle = "tomato"
  context.stroke()
}
`}</code></pre>
    <p>{`And now we've got some bigger and smaller stars to zoom past.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/zYvNrdp?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/zYvNrdp?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Random color of lines`}</h3>
    <p>{`Power to you if you love `}<inlineCode parentName="p">{`tomato`}</inlineCode>{` this much, but I'm getting a little sick of seeing it. Let's add some randomness to the line colors! We've got plenty of options. We could just use white. We could pick a random color and just use that. We could pick a random hue and turn up the lightness so that the colors are all a little more pale. We could use the angle to set the hue so that we get a rainbow moving around the circle. Let's create a function to get the color, and then we can change the implementation to whatever we want while only needing to change the code in one place.`}</p>
    <p>{`To start, let's make a rainbow around the center with the line's angle as input:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getLineColor = ({ angle }) => {
  return \`hsl(\${angle}, 50%, 50%)\`
}

// draw a line from a random angle in a circle
for (let nthLine = 0; nthLine < MAXIMUM_NUMBER_OF_LINES; nthLine++) {
  const angle = Math.random() * 360
  const radians = (Math.PI / 180) * angle
  const x = Math.cos(radians) * maxLength
  const y = Math.sin(radians) * maxLength

  const lineWidth = getRandomLineWidth()
  context.lineWidth = lineWidth
  // get the stroke color
  const strokeStyle = getLineColor({ angle })

  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(x, y)
  context.strokeStyle = strokeStyle // set the calculated stroke color
  context.stroke()
}
`}</code></pre>
    <p>{`From here, we can adjust the body of the `}<inlineCode parentName="p">{`getColor`}</inlineCode>{` function. I'm using the rainbow version, but just uncomment the other options (or write your own!) to explore.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/oNjBbMm?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/oNjBbMm?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Random length and starting position of lines`}</h3>
    <p>{`We don't need every star to start at the center of the circle and extend out to the edge of the screen. Let's randomize the starting positions and the lengths of the lines.`}</p>
    <p>{`For random length, we could just take a random number between `}<inlineCode parentName="p">{`0`}</inlineCode>{` and the `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{` we calculated earlier.`}</p>
    <p>{`And for a random starting position, we can do the same thing and calculate an `}<inlineCode parentName="p">{`offset`}</inlineCode>{` that's between `}<inlineCode parentName="p">{`0`}</inlineCode>{` and `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{`.`}</p>
    <p>{`To make the line, instead of `}<inlineCode parentName="p">{`moveTo(0,0)`}</inlineCode>{` to start our line, we'll `}<inlineCode parentName="p">{`moveTo(offset * Math.cos(radians), offset * Math.sin(radians))`}</inlineCode>{`. And then the `}<inlineCode parentName="p">{`lineTo`}</inlineCode>{` will go to `}<inlineCode parentName="p">{`((offset + length) * Math.cos(radians), (offset + length) * Math.sin(radians))`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// draw a line from a random angle in a circle
for (let nthLine = 0; nthLine < MAXIMUM_NUMBER_OF_LINES; nthLine++) {
  const angle = Math.random() * 360
  const radians = (Math.PI / 180) * angle
  const x = Math.cos(radians) * maxLength
  const y = Math.sin(radians) * maxLength

  const lineWidth = getRandomLineWidth()
  context.lineWidth = lineWidth
  const strokeStyle = getLineColor({ angle })
  const offset = Math.random() * maxLength // calculate the offset
  const length = Math.random() * maxLength // calculate the length

  context.beginPath()
  context.moveTo(offset * Math.cos(radians), offset * Math.sin(radians)) // start at the offset instead of (0,0)
  // add the length to the starting point
  context.lineTo(
    (offset + length) * Math.cos(radians),
    (offset + length) * Math.sin(radians)
  )
  context.strokeStyle = strokeStyle
  context.stroke()
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/OJyWMeX?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/OJyWMeX?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h4>{`Setting a minimum distance from the origin`}</h4>
    <p>{`Right now, the offset could still be 0, and some stars could start at the very center of our hyperspace. If we wanted to add a circular window to the center, though, where no stars could start, we would just need to set a minimum distance from the origin and then ensure no offset is ever less than that minimum:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// KNOBS TO ADJUST
const MAXIMUM_NUMBER_OF_LINES = 200
const MINIMUM_LINE_WIDTH = 1
const MAXIMUM_LINE_WIDTH = 3
const MINIMUM_OFFSET = size * 0.1 // 10% of the canvas size

/* ... */
// update the offset calculation to have a minimum of MINUMUM_OFFSET
const offset = Math.max(MINIMUM_OFFSET, Math.random() * maxLength)
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/ExVZKpq?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/ExVZKpq?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <p>{`The larger the percentage of the canvas size, the bigger the window at the origin`}</p>
    <h3>{`Random origin`}</h3>
    <p>{`The last thing we'll adjust together is the position of the origin. It's been at the very center of the canvas thus far, but we could add some randomness fairly easily. The only thing that we'll need to change once we move the origin is the `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{` of the lines. Since we're shifted off of the center, the distance will decrease to two corners and increase to the two others. We need to find the maximum distance and set that as the new `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{`.`}</p>
    <p>{`To pseudocode this out, we need to:`}</p>
    <ol>
      <li parentName="ol">{`Pick a random `}<inlineCode parentName="li">{`x`}</inlineCode>{` between `}<inlineCode parentName="li">{`0`}</inlineCode>{` and `}<inlineCode parentName="li">{`size`}</inlineCode>{` to split the width into two unequal pieces`}</li>
      <li parentName="ol">{`Pick a random `}<inlineCode parentName="li">{`y`}</inlineCode>{` between `}<inlineCode parentName="li">{`0`}</inlineCode>{` and `}<inlineCode parentName="li">{`size`}</inlineCode>{` to split the height into two unequal pieces`}</li>
      <li parentName="ol">{`Use the larger piece of the width and the larger piece of the height as the inputs for our maxLength calculation`}</li>
    </ol>
    <p>{`For example, if our canvas was 100x100, we might pick a random `}<inlineCode parentName="p">{`x`}</inlineCode>{` at `}<inlineCode parentName="p">{`40`}</inlineCode>{`. If we do that, the canvas is split into `}<inlineCode parentName="p">{`40`}</inlineCode>{` and `}<inlineCode parentName="p">{`100 - 40 = 60`}</inlineCode>{` pixels. And maybe we pick a random `}<inlineCode parentName="p">{`y`}</inlineCode>{` of `}<inlineCode parentName="p">{`30`}</inlineCode>{`, which leads to `}<inlineCode parentName="p">{`30`}</inlineCode>{` and `}<inlineCode parentName="p">{`100 - 30 = 70`}</inlineCode>{` pixels. We'll want to use the 60 and 70 in our `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{` calculation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// find a random origin
const originX = Math.random() * size
const originY = Math.random() * size

// move the origin to the random x and y
context.translate(originX, originY)

// update to calculate the max horizontal and vertical distances from the origin to a corner
const xDistance = Math.max(originX, size - originX)
const yDistance = Math.max(originY, size - originY)

// this is the same calculation
const maxLength = Math.sqrt(xDistance * xDistance + yDistance * yDistance)
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/pojRyXG?editors=0010"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/pojRyXG?editors=0010/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Wrap-up`}</h3>
    <p>{`And that's about it! Thanks for following along. You're now free to fly through a random, colorful hyperspace. Let me know what cool creations you make!`}</p>
    <p>{`Until next time ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      